<template>
  <div class="eagleStrikeItemBox">
    <!-- <div class="title" @click="backfn">
      {{ Title }}
    </div> -->
    <!-- <div class="eagleStrikeItem">
      {{ RotationPieData.value }}
      <RotationPie :RotationPieData="RotationPieData" />
      <RotationText :RotationTextData="RotationText" />
      <Rotation @fn="fn" :RotationData="eagleStrikeData.RotationData" />
    </div> -->

    <div class="item item1">
      <img class="img" :src="eagleStrikeData.RotationData.imgUrl[0]" alt="">
      <span class="name">{{ Title }}</span>
    </div>
    <div class="item item2">
      <div class="chart" ref="vchart">{{ Title }}</div>
    </div>
    <div class="item item3">
      <div class="content" v-for="(item, index) in RotationText" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="text" :class="[RotationText.length == 1 ? 'text-1line' : '']">{{ item.contant }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import echarts from "echarts";
import Rotation from "@/components/eagleStrike/Rotation";
import RotationPie from "@/components/eagleStrike/RotationPie";
import RotationText from "@/components/eagleStrike/RotationText";
import { mapGetters } from "vuex";
// Rotation

export default {
  components: {
    Rotation,
    RotationPie,
    RotationText,
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  props: {
    Path: {
      type: Object,
      default: () => { },
    },
    Title: {
      type: String,
      default: () => "",
    },
    eagleStrikeData: {
      type: Object,
      default: () => { },
    },
    RotationText: {
      default: () => [],
    },
    RotationPieData: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {};
  },
  mounted () {
    // console.log(this.RotationText, 'RotationTextRotationTextRotationText');


    this.chartInit();
  },
  methods: {
    fn (data) {
      // console.log("aaa", data);
      this.$emit("fn", data);
    },
    backfn () {
      let eagleCodeList = this.user_info.eagleCodeList;
      if (
        eagleCodeList.length == 0 ||
        eagleCodeList.indexOf(this.Path.type) == "-1"
      ) {
        return this.$message.info("暂无操作权限!");
      }
      // this.$router.push(this.Path.path);
      window.open("#" + this.Path.path, "_blank");
    },
    chartInit () {
      // console.log(this.RotationPieData, 'RotationPieData');
      // let myChart = echarts.init(document.getElementById("chart"));
      let myChart = echarts.init(this.$refs.vchart);

      let data = [];
      let color = [];
      for (let i = 0; i < this.RotationPieData.data.length; i++) {
        let temp = {};
        temp.name = this.RotationPieData.data[i].name;
        temp.value = this.RotationPieData.data[i].y;
        temp.color = this.RotationPieData.data[i].color;
        data.push(temp);
        color.push(temp.color);
      }

      // console.log(data, 'datadatadatadata');


      let data_name = [];
      let num = 0;
      data.forEach((e) => {
        data_name.push(e.name);
        num += e.value;
      });

      let option = {
        backgroundColor: "rgba(0,0,0,0)",
        tooltip: {
          show: true,
          position: "right",
          trigger: "item",
          showContent: true,
          // formatter: '{b} : {c} ({d}%)'
          formatter: function (obj) {
            return `${obj.data.name}: ${obj.data.value} (${(
              (obj.data.value / num) *
              100
            ).toFixed(2)}%)`;
          },
        },
        legend: [
          {
            // icon: "roundRect",
            // type: 'scroll',
            // type: 'category',
            icon: "rect",
            selectedMode: true,
            // right: "40%",
            // top: "center",
            bottom: -10,
            left: 5,
            orient: "horizontal",
            itemWidth: 15,
            itemHeight: 6,
            // itemGap: 8,
            align: "auto",
            data: data_name,
            formatter: function (name) {
              let s = name.length > 8 ? name.slice(0, 4) + "..." : name;
              const item = data.filter((item) => item.name === name)[0];
              // console.log(item, 'item');
              s = item.value + '\n\r' + '\n\r' + s;
              return s;
            },
            color: color,
            textStyle: {
              color: "rgba(233, 240, 255, 1)",
              fontSize: 12,
              padding: [0, 0, 20, 0]
            },
          },
        ],
        series: [
          {
            avoidLabelOverlap: true,
            hoverAnimation: true, // hover效果
            name: "设备类型",
            type: "pie",
            // 旋转
            // startAngle: 220,
            radius: ["45%", "55%"],
            center: ["50%", "35%"],
            // 延长标线
            label: {
              fontSize: 14,
              position: "outside",
              formatter: "{d}%",
              show: false,
            },
            labelLine: {
              show: false,
              length: 10,
              length2: 10,
              lineStyle: {
                width: 2,
              },
            },
            // itemStyle: {
            //     normal: {
            //         borderColor: "#010c2000",
            //     },
            // },
            color: color,
            data: data,
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.eagleStrikeItemBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80vw;
  background-color: #153A51;

  .item {
    height: 100%;
    // border: 1px solid yellow;
  }

  .item1 {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .img {
      width: 70%;
      height: 70%;
    }

    .name {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 5px;
    }
  }

  .item2 {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;

    .chart {
      width: 84%;
      height: 90%;
      border-radius: 12px;
      border: 1px solid #446174;
    }
  }

  .item3 {
    width: 53%;
    box-sizing: border-box;
    padding: 10px;
    // background-color: yellowgreen;
    overflow-y: auto;

    .content {
      height: auto;
      width: 100%;
      // padding: 10px;
      margin: 20px 0;
      box-sizing: border-box;
      overflow-y: auto;
      // background-color: #446174;

      .title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }

      .text {
        width: 100%;
        height: auto;
        margin-top: 10px;
        overflow: hidden;
        white-space: normal;
        word-wrap: break-word;
        text-overflow: ellipsis;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #92a5b9;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 20px;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
          color: transparent;
        }
      }

      .text-1line {
        -webkit-line-clamp: 5 !important;
      }
    }
  }

  .title_bg {
    top: 70px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 70px;
  }

  .titlew {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 43px;
    width: calc(100% - 20px);
    height: 43px;
    background-image: url("~@/assets/img/eagle_item_title_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 20px;
  }

  // background-image: url("~@/assets/images/EagleStrike/bg3.png");
  .eagleStrikeItem {
    display: flex;
    justify-content: flex-start;
    background-color: rgba(35, 47, 79, 0.9);
  }
}
</style>
