<template>
  <div class="cpt-home-task_count w300">
    <div :class="RotationPieData.name" class="echart-view"></div>
  </div>
</template>

<script>
import API from "@/api";

import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3D from "highcharts/highcharts-3d";
HighchartsMore(Highcharts);
Highcharts3D(Highcharts);

export default {
  props: ["RotationPieData"],
  data() {
    return {
      filter1: {
        organId: null,
        type: null,
      },
      timeChange: 1,
      chartData: [],
      chart: null,
    };
  },
  mounted() {
    this.getChartInfo();
  },
  watch: {
    RotationPieData: {
      // 此处监听variable变量，当期有变化时执行
      handler(item1, item2) {
        console.log(11);
        console.log(this.RotationPieData);
        this.getChartInfo();
        // item1为新值，item2为旧值
      },
      deep: true,
    },
  },
  methods: {
    async getChartInfo() {
      this.init(this.chartData);
    },
    init(item) {
      let _this = this;
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = Highcharts.chart({
        chart: {
          renderTo: document.querySelector(`.${this.RotationPieData.name}`),
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 50,
            beta: 0,
          },
        },
        title: {
          text: "",
        },
        legend: {
          align: "left",
          itemStyle: {
            color: "#FFFFFF",
          },
          itemHoverStyle: {
            color: "#FFFFFF",
          },
        },
        // 去掉 highcharts水印
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            center: ["30%", "50%"],
            showInLegend: true,
            allowPointSelect: true,
            cursor: "pointer",
            depth: 20,
            allowPointSelect: true,
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            events: {
              click: function (e) {},
            },
          },
        },
        series: [
          {
            type: "pie",
            depth: 20,
            size: "100%",
            name: this.RotationPieData.title,
            data: this.RotationPieData.data,
          },
        ],
      });
      this.chart.update({
        chart: {
          backgroundColor: null, //背景为透明，使用rgba(0,0,0,0)或空字符也可以实现透明
        },
      });
    },

    async fliter_active_list(data, item) {
      this.filter1.type = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-home-task_count {
  .echart-view {
    width: 300px;
    height: 246px;
    background: rgba(99, 177, 245, 0.09);
    border-radius: 4px;
    margin-top: 12px;
    margin-left: 15px;
    margin-bottom: 12px;
  }
}
</style>
