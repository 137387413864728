<!-- 头部 -->
<template>
  <div class="cpt-home_header">
    <div class="left">
      <div class="title" @click="handleClick">
        <!-- <img alt="" src="~@/assets/img/eagle-ji-logo.png" /> -->
        <img alt="" src="~@/assets/img/command-kong-logo.png" />
        <span>{{ title }}</span>
      </div>
      <template>
        <div class="menu">
          <ul class="ul">
            <li class="tap" @mouseover="tapyi(item.id)" @mouseout="tapyichu" v-for="item in optionsLeft" :key="item.id" @click="tap_click(item)" :class="{ jin: item.id == JinId }">
              <span>{{ item.title }}</span>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <div class="right">
      <!-- <HeaderRight :data="user" class="jl" /> -->
      <tianqi class="tianqi"></tianqi>
    </div>
  </div>
</template>

<script>
import HeaderRight from '@/components/header-right/index_zhz.vue'
//箭头
import zuo from '@/assets/newImage/zuojian.png'
import zuoJin from '@/assets/newImage/zuojianJin.png'
import you from '@/assets/newImage/youjian.png'
import youJin from '@/assets/newImage/youjianJin.png'
//tap标签背景
import tapLan from '@/assets/newImage/taplan.png'
import tapJin from '@/assets/newImage/tapJin.png'

import tianqi from '@/pages/observe/home/components/header/tianqi'
// bus
import Bus from '@/assets/ligature'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  props: {
    title: {
      type: String,
      default: () => '标题'
    },
    user: {
      type: Object,
      default: () => ({})
    },
    flag: {
      type: Number | String,
      default: () => 1
    }
  },
  data() {
    return {
      JinId: 10,
      zuo: zuo,
      you: you,
      weather: {},
      now: null,
      weatherIcon: null,
      weather_data: {
        weather: '晴',
        icon: require(`@/assets/images/weather/icon/day/01.png`),
        temperature: 0,
        humidity: 0,
        windPower: '',
        windSpeed: '',
        minTemp: '',
        maxTemp: '',
        windDirection: '东北'
      },
      ws: '',
      // tap标签引用的是两个路径地址,这样可以动态加载图片
      tap: tapLan,
      tapJin: tapJin,
      imgId: '',
      week_map: {
        0: '天',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六'
      },
      optionsLeft: [
        { id: 10, title: '首页', path: '/eagleStrike' },
        // 交通改为场景，点击场景跳转默认到交通
        { id: 1, title: '场景', path: '/accident?page=0', type: 'traffic' }

        // { id: 1, title: "森工", path: "/accident?page=1", type: "traffic" },

        // { id: 1, title: "交通", path: "/accident?page=1", type: "traffic" },
        // { id: 2, title: "应急", path: "/accident?page=0", type: "patrol", },
        // { id: 3, title: "食药环", path: "/accident?page=2", type: "food" },
        // { id: 4, title: "网安", path: "/accident?page=3", type: "net" },
        // { id: 5, title: "水利", path: "/accident?page=4", type: "water" },
        // { id: 6, title: "技侦", path: "/accident?page=6", type: "crimes" },

        // { id: 6, title: "喷火器", path: "/fckernel1027" },
        // { id: 7, title: "催泪弹", path: "/fckernel1027" },
        // { id: 8, title: "霹雳火", path: "/fckernel1027" },
        // { id: 9, title: "网枪", path: "/fckernel1027" },
      ]
    }
  },
  components: { HeaderRight, tianqi },
  computed: {
    date() {
      let { now } = this
      return now ? `${now.format('YYYY/MM/DD')}` : '0000/00/00'
    },
    week() {
      let { now, week_map } = this
      return now ? `周${week_map[now.day()]}` : '周N/A'
    },
    time() {
      let { now } = this
      return now ? `${now.format('HH:mm')}` : '00:00'
    },
    ...mapGetters(['user_info'])
  },
  // inject: ["g_weather"],
  mounted() {
    const data = new Date()
    let time = data.getHours()
    this.ws = new WebSocket(process.env.VUE_APP_WS_URL_ALARM)
    let { appid, username } = JSON.parse(localStorage.getItem('user_info')).data
    let token = JSON.parse(localStorage.getItem('user_info')).data['mmc-identity']
    this.ws.onopen = () => {
      this.ws.send(
        JSON.stringify({
          type: 100,
          systemCode: 'mmc',
          state: 1,
          username,
          token,
          appId: appid
        })
      )
    }
    this.ws.onmessage = (e) => {
      let metadata = JSON.parse(e.data)
      // console.log(metadata, '无人机原始数据，可能引起地图压力过大9');
      if (metadata.msgnum === 4131) {
        let dataItem = metadata.data.filter((item) => {
          return item.id == 8
        })
        this.weather_data.minTemp = dataItem[0].minTemp
        this.weather_data.maxTemp = dataItem[0].maxTemp
        this.weatherIcon = require(`@/assets/images/weather/icon/${time >= 18 ? 'night' : 'day'}/${dataItem[0].weatherCode}.png`)
        // this.weatherIcon = require(`@/assets/images/weather/icon/${
        //   time >= 18 ? "night" : "day"
        // }`);
        let v = dataItem[0].windDirection
        // console.log("wea--v:", v);
        if (v == 0) {
          this.weather_data.windDirection = '北'
        } else if (v > 0 && v < 90) {
          this.weather_data.windDirection = '东北'
        } else if (v == 90) {
          this.weather_data.windDirection = '东'
        } else if (v > 0 && v < 90) {
          this.weather_data.windDirection = '东北'
        } else if (v > 90 && v < 180) {
          this.weather_data.windDirection = '东南'
        } else if (v == 270) {
          this.weather_data.windDirection = '西'
        } else if (v > 270 && v < 360) {
          this.weather_data.windDirection = '西北'
        }
      }
    }
    setInterval(() => {
      this.now = dayjs()
    }, 500)

    // Bus.$on('');
    Bus.$on('item-change-flag', (v) => {
      this.JinId = v
    })
  },
  beforeDestroy() {
    this.ws && this.ws.close()
    this.ws = ''
  },
  methods: {
    // 箭头事件
    zuoyi() {
      this.zuo = zuoJin
    },
    zuoyichu() {
      this.zuo = zuo
    },
    youyi() {
      this.you = youJin
    },
    youyichu() {
      this.you = you
    },
    tapyi(id) {
      this.imgId = id
    },
    tapyichu() {
      this.imgId = ''
    },
    // 箭头跳转,思路,输出数组第一个对象,然后push数组最后面,右箭头反之
    zuotiao() {
      const allLength = this.optionsLeft.length * 134
      const boxLength = document.querySelector('.gundong').clientWidth
      if (allLength < boxLength) return
      const listEl = document.querySelector('.ul')
      const leftMove = parseInt(window.getComputedStyle(listEl, null)?.left)
      if (leftMove + boxLength < boxLength) {
        // 滚到
        listEl.style.left = '0px'
      } else if (leftMove < 0) {
        listEl.style.left = leftMove + 134 + 'px'
      }
      //   let a = JSON.parse(JSON.stringify(this.optionsLeft));
      //   let b = a.splice(0, 1);
      //  if(b[0].id == 1){
      //    return
      //  }else{
      //   a.push(b[0]);
      //   this.optionsLeft = a;
      //  }
    },
    youtiao() {
      const allLength = this.optionsLeft.length * 134
      const boxLength = document.querySelector('.gundong').clientWidth
      if (allLength < boxLength) return
      const listEl = document.querySelector('.ul')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      console.log(leftMove, allLength)
      if (leftMove + boxLength >= allLength) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 134) + 'px'
      }
      // let a = JSON.parse(JSON.stringify(this.optionsLeft));
      // let b = a.splice(a.length - 1, 1);
      // if(b[0].id == 1){
      //   return
      // }else{
      //   a.unshift(b[0]);
      // this.optionsLeft = a;
      // }
    },

    // tap标签跳转功能
    tap_click(item) {
      let eagleCodeList = this.user_info['eagleCodeList']
      console.log(eagleCodeList, 'eagleCodeList', item)
      if (item.type) {
        if (eagleCodeList.length == 0 || eagleCodeList.indexOf(item.type) == '-1') {
          return this.$message.info('暂无操作权限!')
        }
      }
      if (item && item.path) {
        // window.open("#" + item.path, "_blank");
        window.open('#' + item.path, '_self')
        // this.$router.push(item.path)
      }
    },

    // 首页跳转功能
    handleClick() {
      // this.$router.push('/home');
      // this.$router.go(0);
      let { href } = this.$router.resolve({ path: '/home' })
      console.log(href, '2222222222 hrefhrefhrefhrefhref')
      window.open(href, '_self')
      this.$router.go(0)
      return
      let a = this.$route.path.includes('1027')
      // console.log(a, 'aaaaaaaaaaaaaaa');
      if (a) {
        let { href } = this.$router.resolve({ path: '/home1027' })
        // console.log(href, '1111111111 hrefhrefhrefhrefhref');
        window.open(href, '_self')
        setTimeout(() => {
          this.$router.go(0)
        }, 100)
      } else {
        let { href } = this.$router.resolve({ path: '/home' })
        // console.log(href, '2222222222 hrefhrefhrefhrefhref');
        window.open(href, '_self')
        setTimeout(() => {
          this.$router.go(0)
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cpt-home_header {
  // width: 100%;
  // height: 68px;
  // z-index: 10;
  // // background: url("~@/assets/img/head_bg.png") no-repeat;
  // background-size: cover;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;

  width: 100%;
  // height: 68px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  // background: url("~@/assets/img/head_bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // width: 1919px;
  height: 64px;
  background: #0e3042;
  // background: #a4becc;
  cursor: pointer;
  z-index: 100;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      // font-size: 30px;
      // font-family: PingFangSC-Semibold, PingFang SC;
      // font-weight: 600;
      // color: #ffffff;
      // margin-left: 40px;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // cursor: pointer;
      // margin-top: -10px;

      // img {
      //   width: 63px;
      //   height: 58px;
      //   margin-right: 43px;
      // }
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      // margin-top: -10px;

      span {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-left: 10px;
      }

      img {
        // width: 63px;
        // height: 58px;
        width: 47px;
        height: 39px;
        margin-right: 8px;
      }
    }

    .menu {
      margin-left: 150px;

      ul {
        list-style: none;
        display: flex;
        height: 100%;
        vertical-align: top;

        .tap {
          width: 128px;
          height: 64px;
          position: relative;
          text-align: center;
          // margin: -8px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.tap {
  // background-image: url("~@/assets/img/head-menu-bg-nomal-128.png");
  background-image: url('~@/assets/img/head-menu-bg-nomal.png');
  background-size: 100% 100%;
  cursor: pointer;
}

.tap:hover {
  // background-image: url("~@/assets/img/head-menu-bg-select-128.png");
  background-image: url('~@/assets/img/head-menu-bg-select.png');
  background-size: 100% 100%;
}

.jin {
  // background-image: url("~@/assets/img/head-menu-bg-select-128.png");
  background-image: url('~@/assets/img/head-menu-bg-select.png');
  background-size: 100% 100%;
}
</style>
