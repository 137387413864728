<template>
  <div class="RotationTextBox">
    <div
      v-for="(item, index) in RotationTextData"
      :key="index"
      class="text-item"
    >
      <div class="ttile" v-if="index < 2">{{ item.title }}</div>
      <div
        v-if="index < 2"
        class="contant"
        :class="RotationTextData.length == 1 ? 'single' : ''"
      >
        {{ item.contant }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["RotationTextData"],
  data() {
    return {
      list: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.RotationTextBox {
  height: 200px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-left: 25px;
  gap: 10px;
  .text-item {
    display: flex;
    flex-direction: column;
    background: rgba(99, 177, 245, 0.09);
    border-radius: 4px;
    padding: 8px;
    .ttile {
      font-family: MicrosoftYaHeiUI-Bold;
      margin-bottom: 10px;
      font-size: 14px;
      color: #00c8fc;
      text-align: left;
      font-weight: 700;
    }
    .contant {
      font-family: MicrosoftYaHeiUI;
      text-indent: 2em;
      font-size: 12px;
      color: #ffffff;
      line-height: 22px;
      font-weight: 400;
      margin-bottom: 20px;
      height: 56px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
        color: transparent;
      }
    }
  }
  .single {
    height: 184px !important;
  }
}
</style>
