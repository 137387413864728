<template>
  <div class="eagleStrike pa">
    <Header class="cp" :title="title" />
    <!-- <img src="~@/assets/images/EagleStrike/eagleStrike-main-bg.png" alt="" class="bg-img" /> -->
    <div class="content-view">
      <EagleStrikeItem class="item item1" @fn="fn1" :Path="pathA" :eagleStrikeData="eagleStrikeA" :key=1
        :RotationText="RotationTextA" Title="森工无人机实战应用" :RotationPieData="RotationPieA"></EagleStrikeItem>
      <EagleStrikeItem class="item item2" @fn="fn2" :Path="pathB" :eagleStrikeData="eagleStrikeB" :key=2
        :RotationPieData="RotationPieB" :RotationText="RotationTextB" Title="应急无人机实战应用"></EagleStrikeItem>
      <EagleStrikeItem class="item item3" @fn="fn3" :Path="pathC" :eagleStrikeData="eagleStrikeC" :key=3
        :RotationText="RotationTextC" :RotationPieData="RotationPieC" Title="食药环无人机实战应用"></EagleStrikeItem>
      <EagleStrikeItem class="item item4" @fn="fn4" :Path="pathD" :eagleStrikeData="eagleStrikeD" :key=4
        :RotationText="RotationTextD" :RotationPieData="RotationPieD" Title="网安无人机实战应用"></EagleStrikeItem>
      <EagleStrikeItem class="item item5" @fn="fn5" :Path="pathE" :eagleStrikeData="eagleStrikeE" :key=5
        :RotationText="RotationTextE" :RotationPieData="RotationPieE" Title="水利实战应用简介"></EagleStrikeItem>
    </div>
  </div>
</template>

<script>

import Header from "@/components/eagleStrike/header/index1027";
import EagleStrikeItem from "@/components/eagleStrike/eagleStrikeItem";
import API from "@/api";
import avatar from "@/assets/zwlstp.png";
export default {
  components: {
    Header,
    EagleStrikeItem,
  },
  data () {
    return {
      title: "场景应用",
      avatar: avatar,
      pathA: { path: "/accident?page=1", type: "traffic" },
      pathB: { path: "/accident?page=0", type: "patrol" },
      pathC: { path: "/accident?page=2", type: "food" },
      pathD: { path: "/accident?page=3", type: "net" },
      pathE: { path: "/accident?page=4", type: "water" },
      // pathE: { path: "/accident1027?page=5", type: "water" },
      eagleStrikeA: {
        RotationData: {
          imgUrl: [
            require("@/assets/images/EagleStrike/i104.png"),
            // require("@/assets/images/EagleStrike/senlin_1.png"),
            // require("@/assets/img/i104.png"),
            require("@/assets/images/EagleStrike/senlin_2.png"),
            require("@/assets/images/EagleStrike/senlin_3.png"),
          ],
          videoUrl: {
            vUrl: require("@/assets/videos/senlin.mp4"),
          },
          item1ImgUrl: [require('@/assets/img/i104.png')],
        },
      },
      eagleStrikeB: {
        RotationData: {
          imgUrl: [
            require("@/assets/images/EagleStrike/i105.png"),
            require("@/assets/images/EagleStrike/img4.png"),
            require("@/assets/images/EagleStrike/img5.png"),
            require("@/assets/images/EagleStrike/img6.png"),
            require("@/assets/images/EagleStrike/img7.png"),
            require("@/assets/images/EagleStrike/img8.png"),
          ],
          videoUrl: {
            vUrl: require("@/assets/videos/yinji.mp4"),
          },
          item1ImgUrl: require('@/assets/img/i104.png'),
        },
      },
      eagleStrikeC: {
        RotationData: {
          imgUrl: [
            require("@/assets/images/EagleStrike/i108.png"),
            require("@/assets/images/EagleStrike/img9.png"),
            require("@/assets/images/EagleStrike/img10.png"),
            require("@/assets/images/EagleStrike/img11.png"),
            require("@/assets/images/EagleStrike/img12.png"),
          ],
          videoUrl: {
            vUrl: require("@/assets/videos/video_c.mp4"),
          },
          item1ImgUrl: require('@/assets/img/i104.png'),
        },
      },
      eagleStrikeD: {
        RotationData: {
          imgUrl: [
            require("@/assets/images/EagleStrike/i106.png"),
            require("@/assets/images/EagleStrike/img2.png"),
            require("@/assets/images/EagleStrike/img1.png"),
            require("@/assets/images/EagleStrike/img11.png"),
            require("@/assets/images/EagleStrike/img12.png"),
          ],
          videoUrl: {
            vUrl: require("@/assets/videos/wangan.mp4"),
          },
          item1ImgUrl: require('@/assets/img/i104.png'),
        },
      },
      eagleStrikeE: {
        RotationData: {
          imgUrl: [
            require("@/assets/images/EagleStrike/i107.png"),
            require("@/assets/images/EagleStrike/shuili_1.png"),
            require("@/assets/images/EagleStrike/shuili_2.png"),
            require("@/assets/images/EagleStrike/shuili_3.png"),
          ],
          videoUrl: {
            vUrl: require("@/assets/videos/video2.mp4"),
          },
          item1ImgUrl: require('@/assets/img/i104.png'),
        },
      },
      RotationTextA: [
        {
          title: "森工应用场景简介",
          contant:
            "结合森工需求，以无人直升机为主，综合多旋翼、复合翼机型，全面推进林场巡、查、防、治，包括林场定期防盗防火巡检、森林资源调查、森林消防灭火、病虫害检测和飞防作业、林场物资运投。结合联飞无人直升机及其他各型号无人机，在多灾种应急救援上开展应用，包括森林消防、应急指挥、应急搜救、应急物资投送、应急通信中继保障、应急测绘调查。",
        },
        {
          title: "森林火灾监测典型应用",
          path: "",
          contant:
            "森林火灾是一种突发性强、破坏性极大的自然灾害。早期监测对于预防森林火灾至关重要。无人机在森林防火 中具有低成本、高时效、机动灵活、应急快速、实时巡查能力强等优势，利用无人机成功探测到火点及覆盖面积，并可报警提示，提供火点的具体坐标，有助于提早发现森林火情，及时掌握火情信息，便于迅速部署防火力量，进而减少生命、财产损失。",
        },
      ],
      RotationTextB: [
        {
          title: "群体性事件处置描述",
          contant:
            "针对群体性聚集事件侦察等任务，需要对特定区域进行长时间的空中识别侦察，地面根据无人机的实时画面，将获取的人脸、车牌自动与警务大数据平台进行比对，将比中的身份、车辆信息在终端显示，协助民警进行现场决策。系统可通过无人机悬空监视，也可寻找合适的观测点智能降落节省电量，进行长时间监视。",
        },
        {
          title: "夜间人员援救简介",
          contant:
            "针对夜间搜救任务，无人机通过微光相机可以有效定位水上遇险事故现场位置与船舶，提高水上遇险搜救效率。识别到人员后，通过抛投挂载抛投救生圈等救援物资，快速救援遇险人员",
        },
        {
          title: "缉捕简介",
          contant:
            "无人机参与处置劫持、涉枪、涉爆等严重暴力犯罪及恐怖事件，可喊话进行心理战，远程回传现场画面到指挥中心，辅助指挥中心决策快速缉捕严重暴力犯罪分子。",
        },
      ],
      RotationTextC: [
        {
          title: "河道排污查处简介",
          contant:
            " 无人机执行河道巡查任务时，通过功能性挂载可自动识别水体，发现水体异样，利用取水器挂载取适量河水，进行污染检验。",
        },
      ],
      RotationTextD: [
        {
          title: "特定目标侦查简介",
          contant:
            "为预防和降低非机动车交通事故，提升群众夜间出行安全系数，公安局交警大队将电动车“亮尾行动”作为重要内容，印刷了5万份反光车贴，在电动车尾部进行张贴",
        },
        {
          title: "交通事故处置实战应用简介",
          path: "",
          contant:
            "在发生交通事故时，特别是早晚高峰和交通拥堵时，警车无法及时到达事故现场，而无人机则能够快速抵达现场进行取证，记录涉事车牌（人像），并可通过喊话器通知轻微事故车辆及时撤离，避免出现大规模拥堵，快速恢复路面交通顺畅。在发生较大交通事故时，无人机在收到指令任务后直接飞往交通事故现场，通过平台获取交通事故现场图，通过交通事故软件可进行事故现场图绘制，输出事故图，生成交通事故报告。",
        },
      ],
      RotationTextE: [
        {
          title: "水利实战应用简介",
          contant:
            "在传统人工巡查中，只能沿着岸边、在桥上或者是坐船进行巡查、取证、拍摄，但是河流附近环境复杂、树木茂密、巡河效率低下、行走不便，还容易发生危险，且大部分的河流并不能通过坐船的方式进行巡河，就会导致巡查成本较高。可设定航拍任务，控制无人机采集河道全区域正射图片、倾斜摄影照片、多光谱照片，全程无需人工参与，无人机自动换电，断点续飞，自动取材，自动上传高清正射照片，由后台系统自动完成二维/三维建模工作，或多光谱分析，为河道生态环境治理提供依据。",
        },
      ],
      RotationPieA: {
        title: "森工实战任务",
        name: "itmeA",
        data: [
          {
            name: "森林消防",
            y: 50,
            sliced: true,
            selected: true,
            color: "#65c232",
          },
          {
            name: "应急救援",
            y: 30,
            sliced: true,
            selected: true,
            color: "#009afc",
          },
          {
            name: "资源监测",
            y: 20,
            sliced: true,
            selected: true,
            color: "#98c379",
          },
        ],
      },
      RotationPieB: {
        title: "应急实战任务",
        name: "itmeB",
        data: [
          {
            name: "群体事件处置",
            y: 1,
            sliced: true,
            selected: true,
            color: "#65c232",
          },
          {
            name: "夜间人员援救",
            y: 1,
            sliced: true,
            selected: true,
            color: "#009afc",
          },
          {
            name: "缉捕",
            y: 1,
            sliced: true,
            selected: true,
            color: "#98c379",
          },
        ],
      },
      RotationPieC: {
        title: "食药环实战任务",
        name: "itmec",
        data: [
          {
            name: "河道排污查处",
            y: 0,
            sliced: true,
            selected: true,
            color: "#98c379",
          },
        ],
      },
      RotationPieD: {
        title: "网安实战任务",
        name: "itmed",
        data: [
          {
            name: "特定目标侦查",
            y: 0,
            sliced: true,
            selected: true,
            color: "#98c379",
          },
        ],
      },
      RotationPieE: {
        title: "水利实战任务",
        name: "itmee",
        data: [
          {
            name: "水上救援",
            y: 0,
            sliced: true,
            selected: true,
            color: "#98c379",
          },
        ],
      },
      options: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
    };
  },
  created () {
    document.title = this.title;
    this.getTaskTypeIdByGuideFn();
  },
  methods: {
    fn1 (id) {
      this.fn(id, 1);
    },
    fn2 (id) {
      this.fn(id, 2);
    },
    fn3 (id) {
      this.fn(id, 3);
    },
    fn4 (id) {
      this.fn(id, 4);
    },
    fn5 (id) {
      this.fn(id, 5);
    },
    async fn (id, type) {
      let imgArr = await API.ACCIDENT.historicalIMG({ id: id });
      let newImg = [];
      if (!imgArr) {
        newImg = [this.avatar];
      } else {
        newImg = imgArr.map((item) => {
          return item.url;
        });
      }
      let video = await API.ACCIDENT.historicalvideos({ id: id });
      let videoList = video && video.object.replay[0];
      let data = {
        vUrl: videoList.urlList[0],
      };
      if (type == 1) {
        this.eagleStrikeA = {
          RotationData: {
            imgUrl: newImg,
            videoUrl: data,
            options: this.options,
            value: id,
          },
        };
      }
      if (type == 2) {
        this.eagleStrikeB = {
          RotationData: {
            imgUrl: newImg,
            videoUrl: data,
            options: this.options2,
            value: id,
          },
        };
      }
      if (type == 3) {
        this.eagleStrikeC = {
          RotationData: {
            imgUrl: newImg,
            videoUrl: data,
            options: this.options3,
            value: id,
          },
        };
      }
      if (type == 4) {
        this.eagleStrikeD = {
          RotationData: {
            imgUrl: newImg,
            videoUrl: data,
            options: this.options4,
            value: id,
          },
        };
      }
      if (type == 5) {
        this.eagleStrikeE = {
          RotationData: {
            imgUrl: newImg,
            videoUrl: data,
            options: this.options5,
            value: id,
          },
        };
      }
    },
    async getTaskTypeIdByGuideFn () {
      let res = await API.ACCIDENT.getTaskTypeIdByGuide();
      console.log("res", res);
      // if (res) {
      //   res.forEach((element) => {
      //     if (element.name == "森工实战") {
      //       this.taskNamesByTypeIdFn(element.id, 1);
      //     } else if (element.name == "食药环实战") {
      //       this.taskNamesByTypeIdFn(element.id, 2);
      //     } else if (element.name == "应急实战") {
      //       this.taskNamesByTypeIdFn(element.id, 0);
      //     } else if (element.name == "网安实战") {
      //       this.taskNamesByTypeIdFn(element.id, 3);
      //     } else if (element.name == "水利实战") {
      //       this.taskNamesByTypeIdFn(element.id, 4);
      //     } else if (element.name == "技侦实战") {
      //       this.taskNamesByTypeIdFn(element.id, 4);
      //     }
      //   });
      // }
    },
    // async taskNamesByTypeIdFn(id, type) {
    //   let res = await API.ACCIDENT.taskNamesByTypeId({ id });
    //   if (res.length > 0) {
    //     if (type == 0) {
    //       this.options = res;
    //     }
    //     if (type == 1) {
    //       this.options2 = res;
    //     }
    //     if (type == 2) {
    //       this.options3 = res;
    //     }
    //     if (type == 3) {
    //       this.options4 = res;
    //     }
    //     if (type == 4) {
    //       this.options5 = res;
    //     }
    //     let num = await API.ACCIDENT.taskCountByTypeId({ id: id });
    //     if (num.length > 0) {
    //       let color = ["#01f5fd", "#009afc", "#98c379"];
    //       let pie = num.map((ele, index) => ({
    //         name: ele.taskGuideName,
    //         y: Number(ele.count),
    //         sliced: true,
    //         color: color[index],

    //         selected: true,
    //       }));
    //       if (type == 0) {
    //         this.RotationPieA = {
    //           title: "森工实战任务",
    //           name: "itmeA",
    //           data: pie,
    //         };
    //       }
    //       if (type == 1) {
    //         this.RotationPieB = {
    //           title: "应急实战任务",
    //           name: "itmeB",
    //           data: pie,
    //         };
    //       }
    //       if (type == 2) {
    //         this.RotationPieC = {
    //           title: "食药环实战",
    //           name: "itmec",
    //           data: pie,
    //         };
    //       }
    //       if (type == 3) {
    //         this.RotationPieD = {
    //           title: "网安实战",
    //           name: "itmed",
    //           data: pie,
    //         };
    //       }
    //       if (type == 4) {
    //         this.RotationPieE = {
    //           title: "水利实战",
    //           name: "itmee",
    //           data: pie,
    //         };
    //       }
    //     }
    //     let imgArr = await API.ACCIDENT.historicalIMG({ id: res[0].id });
    //     var newImg = [];
    //     if (!imgArr) {
    //       newImg = [this.avatar];
    //       // this.$message.info("暂无历史图片");
    //     } else {
    //       newImg = imgArr.map((item) => {
    //         return item.url;
    //       });
    //     }
    //     let data = { vUrl: "xxx" };
    //     let video = await API.ACCIDENT.historicalvideos({ id: res[0].id });
    //     if (video) {
    //       try {
    //         let videoList = video.object.replay[0];
    //         data = {
    //           vUrl: (videoList && videoList.urlList[0]) || "",
    //         };
    //       } catch (error) {}
    //     }
    //     if (type == 1) {
    //       this.eagleStrikeB = {
    //         RotationData: {
    //           // imgUrl: newImg,
    //           // videoUrl: data,
    //           imgUrl: [require("@/assets/images/EagleStrike/7e.jpg")],
    //           videoUrl: {
    //             vUrl: "https://32.128.6.48/uploads/2022/12/15/20221215videoyx4.mp4",
    //           },
    //           options: res,
    //           value: res[0].id,
    //         },
    //       };
    //     } else if (type == 2) {
    //       this.eagleStrikeC = {
    //         RotationData: {
    //           // imgUrl: newImg,
    //           // videoUrl: data,
    //           imgUrl: [require("@/assets/images/EagleStrike/img101.jpg")],
    //           videoUrl: {
    //             vUrl: "https://32.128.6.48/uploads/2022/12/15/20221215yx1011.mp4",
    //           },

    //           options: res,
    //           value: res[0].id,
    //         },
    //       };
    //     } else if (type == 0) {
    //       this.eagleStrikeA = {
    //         RotationData: {
    //           // imgUrl: newImg,
    //           // videoUrl: data,
    //           imgUrl: [
    //             require("@/assets/images/EagleStrike/imga1.png"),
    //             require("@/assets/images/EagleStrike/imga2.png"),
    //           ],
    //           videoUrl: {
    //             vUrl: require("@/assets/videos/video1.mp4"),
    //           },
    //           options: res,
    //           value: res[0].id,
    //         },
    //       };
    //     } else if (type == 3) {
    //       this.eagleStrikeD = {
    //         RotationData: {
    //           // imgUrl: newImg,
    //           // videoUrl: data,
    //           imgUrl: [
    //             require("@/assets/images/EagleStrike/img9.png"),
    //             require("@/assets/images/EagleStrike/img10.png"),
    //             require("@/assets/images/EagleStrike/img11.png"),
    //             require("@/assets/images/EagleStrike/img12.png"),
    //           ],
    //           videoUrl: {
    //             vUrl: require("@/assets/videos/video3.mp4"),
    //           },
    //           options: res,
    //           value: res[0].id,
    //         },
    //       };
    //     } else if (type == 4) {
    //       this.eagleStrikeE = {
    //         RotationData: {
    //           // imgUrl: newImg,
    //           // videoUrl: data,
    //           imgUrl: [
    //             require("@/assets/images/EagleStrike/img4.png"),
    //             require("@/assets/images/EagleStrike/img5.png"),
    //             require("@/assets/images/EagleStrike/img6.png"),
    //             require("@/assets/images/EagleStrike/img7.png"),
    //             require("@/assets/images/EagleStrike/img8.png"),
    //           ],
    //           videoUrl: {
    //             vUrl: require("@/assets/videos/video2.mp4"),
    //           },
    //           options: res,
    //           value: res[0].id,
    //         },
    //       };
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.eagleStrike {
  width: 100vw;
  height: 100vh;
  // background-color: #050510;
  background-color: #0A293A;
  z-index: 11;
  display: flex;
  flex-direction: column;

  .bg-img {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 12;
  }

  .content-view {
    height: calc(100% - 0px);
    width: 100%;
    margin-top: 64px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    overflow-y: auto;
    gap: 12px;
    // padding-top: 0px;
    // padding-bottom: 0px;
    z-index: 13;
    box-sizing: border-box;
    border: 0 solid yellow;
    padding: 25px 0;

    .item {
      width: 65%;
      height: 27%;
      // border: 1px solid yellow;
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}
</style>
