<template>
  <div class="RotationBox">
    <el-select v-model="value" @change="change" placeholder="任务名称">
      <el-option
        v-for="item in RotationData.options"
        :key="item.id"
        :label="item.taskTital"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <div class="jcsb mt12">
      <div class="w66 h160">
        <div class="leftRotation h160">
          <div
            class="h50 cgren mb5 cp"
            :class="itemIndex == index ? 'selectBg' : 'defaultBg'"
            @click="changeFn(index)"
            v-for="(item, index) in RotationData.imgUrl"
            :key="index"
          >
            <el-image
              class="wih100 ht100 dib"
              fit="cover"
              :src="item"
            ></el-image>
          </div>
        </div>

        <div class="side mt10 w66 h20">
          {{ itemIndex + 1 }} /{{ RotationData.imgUrl.length }}
        </div>
      </div>
      <div class="pr rightRotation ml20 cp">
        <div class="left pa left0 w20 h20" @click="Previous"></div>
        <div class="w350 ml25 h191 cp pr20">
          <el-image
            v-if="RotationData && RotationData.imgUrl[itemIndex]"
            class="wih100 ht100 dib"
            fit="cover"
            :src="RotationData.imgUrl[itemIndex]"
          ></el-image>
          <el-image
            v-else
            class="wih100 ht100 dib"
            fit="cover"
            :src="avatar"
          ></el-image>
        </div>
        <div class="right pa cp w20 h20" @click="nextFn"></div>
      </div>
      <div class="w350 ml30">
        <div class="cpt-player-webrtc">
          <Livenvrlayer :data="RotationData.videoUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Livenvrlayer from "@/components/player/livenvr";
import avatar from "@/assets/zwlstp.png";
export default {
  props: ["RotationData"],
  components: {
    Livenvrlayer,
  },
  data() {
    return {
      avatar: avatar,
      itemIndex: 0,
      options: this.RotationData.options,
      value: "",
      url: [
        require("@/assets/images/EagleStrike/img9.png"),
        require("@/assets/images/EagleStrike/img10.png"),
        require("@/assets/images/EagleStrike/img11.png"),
        require("@/assets/images/EagleStrike/img12.png"),
      ],
    };
  },
  watch: {
    RotationData: function (val, old) {
      if (val) {
        console.log("val", val);
        this.value = val.value;
        console.log("this.value", this.value);
      }
    },
    immediate: true,
    deep: true,
  },
  mounted() {},
  methods: {
    change() {
      this.$emit("fn", this.value);
    },
    nextFn() {
      if (this.itemIndex < this.RotationData.imgUrl.length - 1) {
        this.itemIndex++;
      } else {
        this.itemIndex = 0;
      }
    },
    Previous() {
      if (this.itemIndex != 0) {
        this.itemIndex--;
      } else {
        this.itemIndex = this.RotationData.imgUrl.length - 1;
      }
    },
    changeFn(index) {
      this.itemIndex = index;
    },
  },
};
</script>

<style lang="scss">
.RotationBox {
  padding: 12px;
  .el-select {
    border-radius: 2px;
    color: #ffffff;
    width: 140px;
    .el-input {
      background-color: #19243e;
    }
    .el-input__inner {
      width: 140px;
      border: 1px solid rgba(255, 255, 255, 0.21);
      overflow: hidden;
      color: #ffffff;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      background-color: #19243e;
    }
  }
  .leftRotation {
    overflow-y: auto;
    box-sizing: border-box;
    /*定义滚动条样式（高宽及背景）*/
    .selectBg {
      border: 1px solid blue;
    }
    .defaultBg {
      border: 1px solid #010b2c;
    }
  }
  .leftRotation::-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
  }
  .rightRotation {
    .left {
      top: 50%;
      margin-bottom: 5px;
      background-image: url("~@/assets/images/EagleStrike/left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .right {
      top: 50%;
      margin-bottom: 5px;
      right: -5px;
      background-image: url("~@/assets/images/EagleStrike/left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
  .side {
    background: #000d31;
    border: 0 solid #009afc;
    box-shadow: inset 0 0 6px 0 rgba(0, 154, 252, 0.66);
    border-radius: 9.5px;
    font-family: MicrosoftYaHei;
    font-size: 11px;
    line-height: 20px;
    color: #b3bbc5;
    text-align: center;
    font-weight: 400;
  }
}
.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>
