<template>
  <div class="home-header-right_box">
    <div class="right-list_menu">
      <div class="Popout" @click="openmessagebox">
        <img src="~@/assets/zong_lan_ye_img/icon_消息通知.png" alt="" />
        <span>消息通知</span>
      </div>
      <Message @handleClose="showmessage = false" @numbeichange="numbergb" v-show="showmessage"></Message>
      <div class="Popout" @click="handle_logout">
        <img src="~@/assets/new_yingshi/usr.png" alt="" />
        <span>{{ user_info.name }}</span>
      </div>
    </div>

    <div class="isdialogcss" v-show="ismroedialog">
      <div v-interact class="isdialogclose">
        <div class="isdialogmes">订阅更多信息</div>
        <img src="~@/assets/images/accident/close.png" class="img-close" @click="handle_close" />
      </div>
      <div v-for="item in sizeForm" :key="item.id" class="isdialoglist">
        <div class="manylistcss">
          <!-- 未订阅 -->
          <div v-if="item.messageName == '任务审批'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noTaskCount
            }}</span>
          </div>
          <div v-if="item.messageName == '打卡提醒'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noNotificationCount
            }}</span>
          </div>
          <div v-if="item.messageName == '空域权限'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noApplyCount
            }}</span>
          </div>
          <div v-if="item.messageName == '调度审批'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noApprovalCount
            }}</span>
          </div>

          <div v-if="item.messageName == '任务审批'" class="rightmany">
            <el-checkbox class="checkboxaaa" disabled v-model="item.checked" style="margin-right: 5px">订阅</el-checkbox>
          </div>
          <div v-else class="rightmany">
            <el-checkbox class="checkboxaaa" v-model="item.checked" style="margin-right: 5px"
              @change="btnswitch(item)">订阅</el-checkbox>
          </div>
        </div>
      </div>
    </div>

    <!-- 任务详情弹框 -->
    <div class="routesdialog" v-show="isTaskdetails">
      <div v-interact class="routesbox">
        <div class="routestext">任务详情</div>
        <img src="~@/assets/images/accident/close.png" class="img-close" @click="task_close" />
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">任务名称:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.taskTitle }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">航线名称:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.flightName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">任务类别:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.taskCateName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">任务类型:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.taskTypeName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">责任单位:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.organizationName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">飞手:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.flyNames || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">电池编号:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.batteryNumbers || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">预计开始时间:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.startedAt || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">内容描述:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.remark || "暂无" }}
        </div>
      </div>
    </div>

    <!-- 航线详情弹框 -->
    <div class="routesdialog" v-show="isroutesdialog">
      <div v-interact class="routesbox">
        <div class="routestext">航线详情</div>
        <img src="~@/assets/images/accident/close.png" class="img-close" @click="routes_close" />
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">航线名称:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.flightName }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">目标位置:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.destination || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">航线速度:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.speed + "(m/s)" || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">高度:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.averageHeight + "(m)" || "暂无" }}
        </div>
      </div>
    </div>
    <!-- 审批弹框 -->
    <el-dialog :show-close="false" center title="审批" :close-on-click-modal="false" :visible.sync="approve_form.visible"
      :append-to-body="true" top="20em" width="30%">
      <div>
        <div class="w20 h20 pa top20 right20 cp" @click="approve_form.visible = false">
          <img class="dib wih100 ht100" src="~@/assets/images/close.png" alt="" />
        </div>
        <el-form label-width="80px">
          <el-form-item label="备注">
            <el-input v-model="approve_form.approvalRemark" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span v-if="this.active == 3" slot="footer" style="display: flex; justify-content: center">
        <div class="jcsb">
          <div @click="handle_approve(2)" class="w114 h40 lh40 cf f22 cp mr40" style="background-color: #c24848">
            不通过
          </div>
          <div @click="handle_approve(1)" class="w114 h40 lh40 cf f22 cp ml40" style="background-color: #129c9c">
            通过
          </div>
        </div>
      </span>
      <span v-else slot="footer" style="display: flex; justify-content: center">
        <div class="jcsb">
          <div @click="handle_approve(0)" class="w114 h40 lh40 cf f22 cp mr40" style="background-color: #c24848">
            不通过
          </div>
          <div @click="handle_approve(1)" class="w114 h40 lh40 cf f22 cp ml40" style="background-color: #129c9c">
            通过
          </div>
        </div>
      </span>
    </el-dialog>

    <SafeFly :securityFlyVisible="FlyVisible" @close="FlyVisible = false" ref="SafeFlyRef" />
    <!-- 安全检查 -->
    <!-- <checkSafe @close="checkVisible = false" v-if="checkVisible"></checkSafe> -->
  </div>
</template>

<script>
import API from "@/api";
import checkSafe from "./check-safe.vue";
import SafeFly from "./safeFly.vue";
import Message from "@/pages/home/Confirmation_Dialogs/inform";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    activeC: {
      type: Number,
      default: () => 0,
    },
  },
  components: {
    checkSafe,
    SafeFly,
    Message,
  },
  computed: {
    weather () {
      return this.g_weather();
    },
    //全部总和
    totalListLength () {
      return (
        this.Digitaltipdata.taskCount +
        this.Digitaltipdata.notificationCount +
        this.Digitaltipdata.applyCount +
        this.Digitaltipdata.approvalCount +
        this.Digitaltipdata.totalNoCount
      );
    },
    ...mapGetters(["user_info"]),
  },
  data () {
    return {
      number: null,
      // 消息提示框
      showmessage: false,
      //首页提示框
      Promptbox: true,
      // 报表导出
      tableVisible: false,
      FlyVisible: false,
      // 安全检查
      checkVisible: true,
      //调度审批数据
      schedullist: [],
      //数字提示
      Digitaltipdata: [],
      //任务详情数据
      Taskdetailslist: [],
      //任务详情弹框
      isTaskdetails: false,
      //航线详情数据
      routesList: [],
      //航线详情弹框是否关闭
      isroutesdialog: false,
      //更多弹框的是否关闭
      ismroedialog: false,
      //更多列表的数据
      sizeForm: [],

      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      // 更多信息的页签头
      tagnamelist: [],
      check_list: [],
      airspace_list: [],
      map: {
        type: {
          0: "申报",
          1: "报备",
        },
      },
      active: 0,
      pdata: {},
      data: {},
      // list: {
      //   duty: [],
      //   check: [],
      // },
      filter: {
        name: null,
        orgId: null,
      },
      options: [],
      list_duty_arr: [],
      list_check_arr: [],
      securityList: [],
      securityFlyVisible: false,
      type: 1,
      id: null,
    };
  },
  async mounted () {
    let flag = localStorage.getItem("flag")
      ? localStorage.getItem("flag")
      : JSON.parse(localStorage.getItem("user_info")).data.checkStatus;
    if (flag == 1) {
      this.checkVisible = false;
    } else {
      this.checkVisible = true;
    }
    this.id = this.user_info.departmentId;
    this.getPoliceWorkLevel();

    // title
    this.arilist();
    await this.getOptions();
    await this.getCheckList();
    await this.get_duty();
    await this.list_duty();
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    init_ws () {
      const data = new Date();
      let time = data.getHours();
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      let ws = new WebSocket(ws_url_al);
      let _this = this;
      ws.onopen = () => {
        console.log("建立连接");
      };

      ws.onmessage = (e) => {
        let metadata = JSON.parse(e.data);
        // console.log(metadata, '无人机原始数据，可能引起地图压力过大15');
        if (metadata.msgnum === 4134) {
          // 临时禁飞区
        } else if (metadata.msgnum == 4133) {
          // this.g_cesium_resetJfq()
        }
      };

      ws.onerror = (error) => {
        //console.log("error", error);
      };

      ws.onclose = (data) => {
        console.log("onclose", data);
      };
    },
    handle_activeC (val) {
      this.activeC = val;
      this.$emit("changactive", val);
    },
    numbergb (num) {
      this.number = num;
    },
    ...mapActions("user", ["Logout"]),
    checkSafe () { },
    //打开消息提示框
    openmessagebox () {
      this.showmessage = true;
    },
    async setPoliceWorkLevel () {
      if (this.id != 1) {
        return;
      } else {
        if (this.type == 1) {
          this.type = 2;
        } else {
          this.type = 1;
        }
        let res = await API.HOME.setPoliceWorkLevel({
          id: 1,
          type: this.type,
        });
      }
    },
    async getPoliceWorkLevel () {
      let res = await API.HOME.getPoliceWorkLevel();
      this.type = res.type;
    },
    async getCheckList () {
      // let res = await API.PERSON.CheckInfo(this.filter);
      // this.list_check_arr = res || [];
      this.list && (this.list.check =  []);
    },
    async securityFly () {
      this.FlyVisible = true;
      this.$refs.SafeFlyRef && this.$refs.SafeFlyRef.securityFly();
      // let res = await API.SECURITYFLY.Security(3);
      // this.securityList = res;
    },
    handle_detail () {
      let { href } = this.$router.resolve({ path: "/command?page=6" });
      window.open(href, "_blank");
    },
    async get_duty () {
      let data = await API.USER.GetDuty();

      this.pdata = data;
    },
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async list_duty () {
      let data = await API.USER.ListDuty();
      this.list_duty_arr = data || [];
    },

    handle_active (e) {
      this.active = e;
    },
    //数字提示
    async upDigitaltip () {
      this.Digitaltipdata = await API.USER.Digitaltip();
    },
    //任务详情按钮
    async btnTaskdetails (id) {
      this.isTaskdetails = true;
      let res = await API.USER.detailslist(id);
      this.Taskdetailslist = res || [];
    },
    //航线详情按钮
    async btnRoutesList (id) {
      this.isroutesdialog = true;
      let res = await API.USER.routesListqq(id);
      this.routesList = res || [];
    },
    //对√得切换
    async btnswitch (item) {
      if (item.messageStatus == 1) {
        item.status = 0;
      } else if (item.messageStatus == 0) {
        item.status = 1;
      }
      await API.USER.issubscribe(item);
      this.arilist();
      this.btnMore();
    },
    //更多的点击按钮
    async btnMore () {
      this.ismroedialog = true;
      let res = await API.USER.ManyBtnList();
      const data = res.map((item) => {
        if (item.messageStatus == 1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      });
      this.sizeForm = data || [];
    },
    //左点击滑动按钮
    handClickleft () {
      this.handle_active(this.tagnamelist[this.tagnamelist.length - 1].id - 1);
      // //删除最后一个并添加到第一个
      this.tagnamelist.unshift(this.tagnamelist.pop());
    },
    //右点击按钮
    handClickright () {
      this.tagnamelist.push(this.tagnamelist.shift());
      this.handle_active(this.tagnamelist[0].id - 1);
    },
    //更多的关闭按钮
    handle_close () {
      this.ismroedialog = false;
    },
    //任务详情关闭按钮
    task_close () {
      this.isTaskdetails = false;
    },
    // 航线详情的关闭按钮
    routes_close () {
      this.isroutesdialog = false;
    },
    ...mapActions("user", ["Logout"]),
    //active展示具体那个页面,handle_active点击事件触发的
    link_chat () {
      window.open(
        `${process.env.VUE_APP_CHAT_URL}:3000/?role=${this.user_info.username}`,
        "_blank"
      );
    },
    async handle_confirm (item) {
      await API.USER.CheckListConfirm({ id: item.id });
      this.get_check_list();
      this.$el_message("已查看");
    },
    handle_logout () {
      this.$el_confirm("确定要退出登录吗？", () => {
        this.Logout();
        sessionStorage.clear();
        localStorage.clear();
      });
    },
    async get_list () {
      let res = await API.TASK.ListMessage();
      this.list = res || [];
    },
    //空域权限
    async get_arplist () {
      let res = await API.USER.airspaceqx();
      if (res) {
        this.airspace_list = res.data || [];
      }
    },
    //schedullist(调度审批列表)
    async get_schedullist () {
      let res = await API.USER.schedulqx();
      this.schedullist = res || [];
    },
    //check_list
    async get_check_list () {
      //vuex
      let id = this.user_info.uid;
      //传递过去的值是receiveId   返回过来的值赋值给Res
      let res = await API.USER.CheckList({ receiveId: id });
      //res赋值给check_list
      this.check_list = res || [];
    },
    start_timer () {
      this.timer = window.setInterval(() => {
        this.upDigitaltip();
        // 任务审批
        this.get_list();
        // 空域权限
        this.get_arplist();
        // 打卡提醒
        this.get_check_list();
        //调度审批
        this.get_schedullist();
      }, 3000);
    },
    stop_timer () {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },

    handle_show_approve (val) {
      this.curr_task = val;
      this.approve_form.visible = true;
    },
    //任务审批和空域权限的审批(用active来判断的，因为active不能同时显示两个)active == 0 任务审批 1 打卡提醒  2空域权限  3调度审批
    async handle_approve (val) {
      if (this.active == 0) {
        this.$el_confirm(
          "确定提交么？",
          async () => {
            let res = await API.TASK.Edit({
              id: this.curr_task.id,
              status: val,
              taskCateId: this.curr_task.taskCateId,
              approvalRemark: this.approve_form.approvalRemark,
            });
            if (res) {
              this.$el_message("审批成功");
            }
            this.get_list();
            this.upDigitaltip();
            this.approve_form.visible = false;
          },
          () => {
            this.approve_form.approvalRemark = null;
            this.approve_form.visible = false;
          }
        );
      } else if (this.active == 2) {
        let id = this.curr_task.id;
        this.$el_confirm(
          "确定提交么？",
          async () => {
            await API.USER.approval(id, val);
            this.$el_message("审批成功");
            this.get_list();
            this.upDigitaltip();
            this.approve_form.visible = false;
          },
          () => {
            this.approve_form.approvalRemark = null;
            this.approve_form.visible = false;
          }
        );
      } else if (this.active == 3) {
        let parameter = this.curr_task;
        this.$el_confirm(
          "确定提交么？",
          async () => {
            await API.USER.approvalqx({
              ...parameter,
              val,
            });
            this.$el_message("审批成功");
            this.get_list();
            this.upDigitaltip();
            this.approve_form.visible = false;
          },
          () => {
            this.approve_form.approvalRemark = null;
            this.approve_form.visible = false;
          }
        );
      }
    },
    //获取更多信息的tab页签头显示
    async arilist () {
      this.tagnamelist = [];
      let res = await API.USER.ManyBtnList();
      //messageStatus==1的时候就代表已订阅显示在页签头(push进tagnamelist)
      res.forEach((val) => {
        if (val.messageStatus == 1) {
          this.tagnamelist.push(val);
        }
      });
      if (this.tagnamelist.length > 0) {
        this.upDigitaltip();
        // 任务审批
        this.get_list();
        // 空域权限
        this.get_arplist();
        // 打卡提醒
        this.get_check_list();
        // // 获取数据列表
        // this.start_timer();
      }

      this.ismroedialog = false;
    },

    // 修复点击单位下拉框消失问题
    handleChange (val) {
      if (val == 0) {
        let _this = this;
        this.$refs.cascader.$refs.panel.$el.addEventListener(
          "click",
          function () {
            _this.$refs.dropdown.show();
          }
        );
      } else {
        this.$refs.dropdown.show();
        this.$refs.cascader.dropDownVisible = false;
      }
    },
  },

  destroyed () {
    this.stop_timer();
  },
  inject: ["handle_report_show"],
};
</script>
<style lang="scss" scoped>
.el-dialog {
  background: transparent;
  background-image: url("~@/assets/images/approve.png");
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

.el-dialog__title {
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.el-dialog__header {
  text-align: left;
  margin-left: 100px;
}

.el-textarea__inner {
  color: #ffff;
  background-color: #000000;
  border: 1px solid #08c2d1;
}

.el-form-item__label {
  color: #fff;
}

.right-list_menu {
  height: 100%;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #ffffff;

  .Popout {
    width: 80px;
    height: 58px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 22px;
      height: 24px;
      margin-bottom: 5px;
    }
  }
}
</style>
